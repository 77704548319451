/* QR Code Container */
.qr-container {
    display: flex;
    justify-content: center;
    gap: 20px; /* Space between QR codes */
    flex-wrap: wrap; /* Ensures wrapping on smaller screens */
  }
  
  .qr-item {
    text-align: center;
    flex: 1 1 calc(50% - 20px); /* Two items per row with space */
    max-width: 400px; /* Max width for larger screens */
  }
  
  .qr-code {
    max-width: 100%;
    height: auto;
    border: 1px solid #ddd; /* Optional: Add border around images */
    border-radius: 8px;
  }
  
  /* Responsive Adjustments */
  @media (max-width: 576px) {
    .qr-item {
      flex: 1 1 100%; /* Full width for small screens */
    }
  }
  